import { Component, Input, OnInit, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { CreditAccount, UserContainer } from 'src/app/shared/models';
import { AccountState } from 'src/app/shared/store/account/+state/account.reducer';
import { selectAccountById } from 'src/app/shared/store/account/+state/account.selectors';
import { AccountActions } from 'src/app/shared/store/account/+state/account.actions';
import { Observable } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { CARRY_FORWARD_ACCOUNT_ID } from 'src/app/shared/data/constants';

@Component({
  selector: 'app-account-card',
  standalone: true,
  imports: [AsyncPipe],
  templateUrl: './account-card.component.html',
  styleUrl: './account-card.component.scss',
})
export class AccountCardComponent implements OnInit {
  private store = inject(Store);

  @Input() owner?: UserContainer;
  @Input() accountId?: string;
  @Input() account?: CreditAccount;

  @Input() type: 'text' | 'card' = 'text';

  account$?: Observable<AccountState>;
  CARRY_FORWARD_ACCOUNT_ID = CARRY_FORWARD_ACCOUNT_ID;

  ngOnInit(): void {
    if (
      this.accountId &&
      this.accountId !== CARRY_FORWARD_ACCOUNT_ID &&
      this.owner?.id
    ) {
      this.account$ = this.store.select(selectAccountById(this.accountId));

      this.store.dispatch(
        AccountActions.loadAccount({
          accountId: this.accountId,
          agencyId: this.owner.id,
          userType: this.owner.type,
        }),
      );
    }
  }
}
