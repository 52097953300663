@switch (type) {
  @case ('text') {
    @if (accountId && accountId === CARRY_FORWARD_ACCOUNT_ID) {
      Carry Forward
    } @else {
      @if (account$ | async; as account) {
        {{ account.account?.name }}
      } @else {
        <div class="skeleton h-4 w-32"></div>
      }
    }
  }

  @case ('card') {
    @if (accountId && accountId === CARRY_FORWARD_ACCOUNT_ID) {
      Carry Forward
    } @else {
      @if (account$ | async; as account) {
        <div class="flex items-center space-x-3">
          <!-- @if (account.profileImage) {
            <div class="avatar">
              <div class="mask mask-circle w-8 h-8">
                <img [src]="account.profileImage" alt="" />
              </div>
            </div>
          } @else {
            <div class="flex justify-center items-center w-8 h-8">
              <fa-icon [icon]="faUser"></fa-icon>
            </div>
          } -->
          <div>
            <div class="font-bold">
              {{ account.account?.name }}
            </div>
          </div>
        </div>
      } @else {
        <!-- {{ userId }} -->
        <div class="flex items-center space-x-3">
          <!-- <div class="avatar">
            <div class="mask mask-squircle w-12 h-12">
              <div class="skeleton w-12 h-12 rounded-none"></div>
            </div>
          </div> -->
          <div>
            <div class="skeleton h-4 w-32"></div>
          </div>
        </div>
      }
    }
  }
}
