import { AsyncPipe, CurrencyPipe, DatePipe } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  inject,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import {
  faArrowDown,
  faArrowUp,
  faChevronLeft,
  faChevronRight,
  faExclamationTriangle,
  faEye,
  faUserTie,
} from '@fortawesome/pro-regular-svg-icons';
import { Store } from '@ngrx/store';
import { Observable, Subscription, takeWhile } from 'rxjs';
import {
  BaseUser,
  Commission,
  CommissionType,
  UserType,
} from 'src/app/shared/models';
import { selectPermissionsGranted } from 'src/app/shared/store/permission-granted/+state/permission-granted.selectors';
import { AgencyTagComponent } from '../../ui-components/agency-tag/agency-tag.component';
import { AuthService } from 'src/app/shared/services/auth.service';
import { carrierMap } from '../../commission-statistics/commission-statistics/carrier';
import { PayoutStatisticsService } from '../../commission-statistics/payout-statistics.service';
import {
  commissionRateMap,
  planTypeMap,
} from 'src/app/shared/data/commissions';
import {
  ModalContainerService,
  ShowModalComponents,
} from 'src/app/shared/services/modal-container.service';
import { AccountCardComponent } from '../../account/account-card/account-card.component';
import { UserCardComponent } from '../../ui-components/user-card/user-card.component';
import { payoutTypeMap } from 'src/app/shared/data/payoutType';
import { TooltipComponent } from '../../ui-components/tooltip/tooltip.component';

@Component({
  selector: 'app-payouts-table',
  standalone: true,
  imports: [
    AccountCardComponent,
    AgencyTagComponent,
    AsyncPipe,
    CurrencyPipe,
    DatePipe,
    FaIconComponent,
    TooltipComponent,
    UserCardComponent,
  ],
  providers: [CurrencyPipe],
  templateUrl: './payouts-table.component.html',
  styleUrl: './payouts-table.component.scss',
})
export class PayoutsTableComponent implements OnInit, OnDestroy {
  private authService = inject(AuthService);
  private cdr = inject(ChangeDetectorRef);
  private currencyPipe = inject(CurrencyPipe);
  private modalContainerService = inject(ModalContainerService);
  private payoutStatisticsService = inject(PayoutStatisticsService);
  private store = inject(Store);

  faArrowDown = faArrowDown;
  faArrowUp = faArrowUp;
  faChevronLeft = faChevronLeft;
  faChevronRight = faChevronRight;
  faExclamationTriangle = faExclamationTriangle;
  faEye = faEye;
  faUserTie = faUserTie;

  // @ViewChild('payoutsDetailModal')
  // payoutsDetailModal?: PayoutsDetailModalComponent;
  carrierMap = carrierMap;
  planTypeMap = planTypeMap;

  payoutTypeMap = payoutTypeMap;

  keys?: any;

  toggleSort(field: string, isInverted = false) {
    if (this.filter['order']?.field === field) {
      this.filter['order'] = {
        field: this.filter['order'].field,
        direction: this.filter['order'].direction === 'asc' ? 'desc' : 'asc',
        isInverted,
      };
    } else {
      this.filter['order'] = {
        field,
        direction: 'asc',
        isInverted,
      };
    }
    this.filterChanged.emit(this.filter);
    this.cdr.detectChanges();
  }

  @Input() showAllProfiles?: boolean;
  @Input() data$?: Observable<{
    loading: false;
    payouts: Commission[] | null;
    payout: Commission | null;
    error: null;
  }>;
  @Input() filter?: any;
  @Input() userId?: any;

  @Output() filterChanged = new EventEmitter<any>();

  @ViewChild('table') table?: ElementRef;
  @ViewChild('container') container?: ElementRef;

  agencyMap: { [key: string]: BaseUser } = {};

  dateToday = new Date().toISOString().split('T')[0];
  showArrows = false;

  permissionsGrantedSubscription?: Subscription;
  ngOnInit() {
    this.data$?.pipe(takeWhile((x) => !x?.payouts, true)).subscribe((data) => {
      if (data.payouts) {
        setTimeout(() => {
          const containerWidth = this.container?.nativeElement.offsetWidth;
          const tableWidth = this.table?.nativeElement.scrollWidth;

          this.showArrows = tableWidth > containerWidth;
          this.cdr.detectChanges();
        }, 10);
      }
    });

    this.authService.currentUser$
      .pipe(takeWhile((u) => !u.userType, true))
      .subscribe((user) => {
        if (user.userType) {
          const keys: {
            id: string;
            title: string;
            key?: string[];
            keyDot?: string;
            primitive?: boolean;
            type?: 'CURRENCY' | 'DATE';
          }[] = [
            {
              id: 'agent',
              title: 'Agent',
            },
            {
              id: 'agent-npn',
              title: 'Agent NPN',
              key: ['agent', 'npn'],
              keyDot: 'agent.npn',
              // primitive: true
            },
            {
              id: 'agency',
              title: 'Agency',
              keyDot: 'agency.name',
            },
            {
              id: 'carrier',
              title: 'Carrier',
              keyDot: 'carrier.id',
            },
            {
              id: 'id',
              title: 'Policy ID',
              keyDot: 'id',
            },
            {
              id: 'type',
              title: 'Plan Type',
              key: ['planTypePayout'],
              keyDot: 'planTypePayout',
            },
            {
              id: 'state',
              title: 'State',
              key: ['state'],
              keyDot: 'state',
              primitive: true,
            },
            {
              id: 'effectiveDate',
              title: 'Effective Date',
              key: ['effectiveDate'],
              keyDot: 'effectiveDate.timestamp',
              primitive: true,
              type: 'DATE',
            },
            {
              id: 'statementDate',
              title: 'Statement Date',
              key: ['statementDate'],
              keyDot: 'statementDate.timestamp',
              primitive: true,
              type: 'DATE',
            },
          ];

          // if (user.userType === UserType.AGENCY) {
          //   keys.push({
          //     id: 'payout',
          //     title: 'Payout',
          //     key: ['payout', 'personal'],
          //     // key: ['payouts', 'amountAgency'],
          //     primitive: true,
          //     type: 'CURRENCY',
          //   });
          //   keys.push({
          //     id: 'payout',
          //     title: 'Payout Agent',
          //     key: ['payout', 'total'],
          //     // key: ['payouts', 'amountAgent'],
          //     primitive: true,
          //     type: 'CURRENCY',
          //   });
          // } else {
          keys.push({
            id: 'payout',
            title: 'Payout Total',
            key: ['payout', 'total'],
            keyDot: 'payout.total',
            primitive: true,
            type: 'CURRENCY',
          });
          keys.push({
            id: 'payoutPersonal',
            title: 'Payout Personal',
            key: ['payout', 'personal'],
            keyDot: 'payout.personal',
            // primitive: true,
            type: 'CURRENCY',
          });

          keys.push({
            id: 'paymentDescription',
            title: 'Payment Description',
            key: ['identifiedResult', 'operationMessage'],
            keyDot: 'identifiedResult.operationMessage',
            // primitive: true,
          });

          this.keys = keys;
        }
      });

    this.permissionsGrantedSubscription = this.store
      .select(selectPermissionsGranted)
      .subscribe((permissions) => {
        if (permissions.permissionsGranted) {
          for (const permission of permissions.permissionsGranted) {
            if (
              permission.user &&
              permission.user.id &&
              permission.user.userType === UserType.AGENCY
            ) {
              // this.agencyMap[permission.user.id] = permission.user;
              // this.userCacheService.addToCache(
              //   permission.user.id,
              //   permission.user
              // );
            }
          }
        }
      });
  }

  ngOnDestroy() {
    this.permissionsGrantedSubscription?.unsubscribe();
  }

  scrollLeft() {
    if (this.container)
      this.container.nativeElement.scrollTo({
        left: this.container.nativeElement.scrollLeft - 250,
        behavior: 'smooth',
      }); // Adjust the scroll step as needed
  }

  scrollRight() {
    if (this.container)
      this.container.nativeElement.scrollTo({
        left: this.container.nativeElement.scrollLeft + 250,
        behavior: 'smooth',
      }); // Adjust the scroll step as needed
  }

  getValue(data: any, k: any) {
    let res;
    if (k.key) {
      if (k.key.length === 3) {
        res = data[k.key[0]]?.[k.key[1]]?.[k.key[2]];
      } else if (k.key.length === 2) {
        if (k.type === 'DATE') {
          const day = ('0' + data[k.key[0]]?.[k.key[1]]?.day).slice(-2);
          const month = ('0' + data[k.key[0]]?.[k.key[1]]?.month).slice(-2);
          return `${month}/${day}/${data[k.key[0]]?.[k.key[1]]?.year}`;
        } else {
          res = data[k.key[0]]?.[k.key[1]];
        }
      }
      if (k.key.length === 1) {
        if (k.type === 'DATE') {
          if (data[k.key[0]]?.timestamp) {
            const day = ('0' + data[k.key[0]]?.day).slice(-2);
            const month = ('0' + data[k.key[0]]?.month).slice(-2);
            return `${month}/${day}/${data[k.key[0]]?.year}`;
          }
        }
        res = data[k.key[0]];
      }
    }

    if (k.type === 'CURRENCY') {
      return this.currencyPipe.transform(res);
    }
    return res;
  }

  getId(id: string) {
    const split = id.split('de-');

    if (split.length === 2) {
      return split[1];
    }
    return split[0];
  }

  getPayoutIdentifiedText(payoutRaw: unknown) {
    const payout = Commission.fromJSON(payoutRaw);
    const textComponents = [];

    if (payout.type === CommissionType.CT_STREET_LEVEL) {
      textComponents.push('Street Level');

      const identifiedResult = payout.identifiedResult;
      if (!identifiedResult?.df2) {
        if (identifiedResult?.df1?.columnDetail?.planType) {
          textComponents.push(
            planTypeMap[identifiedResult?.df1?.columnDetail?.planType].title,
          );
        }

        if (identifiedResult?.df1?.columnDetail?.rate) {
          textComponents.push(
            commissionRateMap[identifiedResult.df1.columnDetail.rate].title,
          );
        }

        let stateName = identifiedResult?.df1?.columnDetail?.state ?? 'Unknown';
        if (stateName?.toLowerCase() === 'other') {
          stateName = 'National';
        }
        textComponents.push(stateName);

        textComponents.push(payout.effectiveDate?.month);
      }
    } else if (payout.type === CommissionType.CT_OVERRIDE) {
      textComponents.push('Agency Level');
    }
    return textComponents.join(' ');
  }

  showPayout(payout: Commission) {
    console.log('editPayout', payout, this.userId);
    if (!this.userId) {
      return;
    }
    // this.payoutsDetailModal?.open(payout, this.userId, this.filter);

    this.modalContainerService.open(ShowModalComponents.PAYOUT, {
      payout,
      userId: this.userId,
      parentFilter: this.filter,
    });
  }

  getCommissionIdentifiedText(commission: Commission) {
    return this.payoutStatisticsService.getCommissionIdentifiedText(commission);
  }

  getKey(object: any, key: string) {
    return object[key];
  }
}
